import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Container, FormControl, FormGroup, FormLabel } from '../../styles';

import Card from '../../components/card';
import ChangeLanguage from '../../components/change-language';
import ProgressBar from '../../components/progress-bar';

import { Col, Row } from 'react-bootstrap';
import Button from '../../components/button';
import { RootState } from '../../configStore';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { setReservation } from '../../reducers/reservation.reducer';
import { formatterCPF } from '../../utils/formatter';
import { isCPF } from '../../utils/validate';

const IdentificationScreen = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const reservation = useAppSelector(
    (state: RootState) => state.reservation.data,
  );

  const [documentType, setDocumentType] = useState('CPF');
  const [document, setDocument] = useState('');

  useEffect(() => {
    setDocumentType(reservation.guest.documentType || 'CPF');
    setDocument(reservation.guest.document || '');
  }, [reservation]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      documentType,
      document: document ? formatterCPF(document) : '',
      submit: null,
    },
    validationSchema: Yup.object({
      documentType: Yup.string().required(
        t('identification.errors.document.required'),
      ),
      document: Yup.string()
        .required(t('identification.errors.document.required'))
        .test(
          'document-check',
          t('identification.errors.document.isInvalid'),
          async (value) => isCPF(value),
        ),
    }),
    onSubmit: async (values, helpers) => {
      try {
        const { documentType, document } = values;
        dispatch(
          setReservation({
            ...reservation,
            guest: { ...reservation.guest, documentType, document },
          }),
        );
        navigate(`/${reservation.id}/personal-information`);
      } catch (error: any) {
        let message = 'Ops! Algo de errado aconteceu';
        if (
          error.response &&
          error.response.data &&
          error.response.data.message[0]
        ) {
          message = error.response.data.message.join('\r\n');
        }
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: message });
        helpers.setSubmitting(false);
      }
    },
  });

  return (
    <Container>
      <br />
      <ProgressBar
        color={reservation.hotel.color}
        title={t('identification.title')}
        step={1}
      />
      <br />
      <Card
        title={t('identification.title')}
        caption={t('identification.caption')}
      >
        <Row className="mb-3">
          <Col>
            <form noValidate onSubmit={formik.handleSubmit}>
              <FormGroup className="mb-3" controlId="validationFormik03">
                <FormLabel>CPF</FormLabel>
                <FormControl
                  name="document"
                  value={formik.values.document}
                  onBlur={formik.handleBlur}
                  onChange={(e: any) => {
                    formik.setFieldValue(
                      'document',
                      formatterCPF(e.target.value),
                    );
                  }}
                  isInvalid={
                    !!(formik.touched.document && formik.errors.document)
                  }
                />
                <FormControl.Feedback type="invalid">
                  {formik.touched.document && formik.errors.document}
                </FormControl.Feedback>
              </FormGroup>
              <br />
              <Button
                type="submit"
                backgroundColor={reservation.hotel.color}
                text={t('common.continue')}
              />
            </form>
          </Col>
        </Row>
        <Row className="d-block d-sm-none">
          <Col>
            <br />
            <ChangeLanguage backgroundColor={'#EEEEEE'} />
          </Col>
        </Row>
      </Card>
    </Container>
  );
};

export default IdentificationScreen;
