import ptBrTranslations from './pt-br';
import enUsTranslations from './en-us';
import esESTranslations from './es-ES';

const translations = {
  'pt-BR': ptBrTranslations,
  'en-US': enUsTranslations,
  'es-ES': esESTranslations,
};

export default translations;
