import { useNavigate } from 'react-router-dom';
import { ReactComponent as CheckedSvg } from '../../assets/svg/checked.svg';
import { Text } from '../../styles';
import { Container, Content, Title } from './styles';

type Props = {
  caption?: string;
  checked?: boolean;
  first?: boolean;
  title?: string;
  children?: JSX.Element | JSX.Element[];
  path?: string;
};

const Card = ({ caption, checked, first, title, children, path }: Props) => {
  const navigate = useNavigate();
  return (
    <Container checked={checked} first={first}>
      {title && (
        <Title
          onClick={() => path && navigate(path)}
          style={{ cursor: path ? 'pointer' : 'auto' }}
        >
          <div style={{ width: '100%' }}>
            <Text
              size={14}
              weight={500}
              color={checked ? '#5B5B5B' : '#000000'}
            >
              {title}
            </Text>
            {caption && (
              <Text
                size={12}
                weight={400}
                color={'#000000'}
                style={{ marginTop: 13 }}
              >
                {caption}
              </Text>
            )}
          </div>
          {checked && <CheckedSvg style={{ marginRight: 5 }} />}
        </Title>
      )}
      <Content checked={checked}>{children}</Content>
    </Container>
  );
};

export default Card;
