import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  Container,
  FormControl,
  FormGroup,
  FormLabel,
  FormSelect,
  Separator,
} from '../../styles';

import Button from '../../components/button';
import Card from '../../components/card';
import ChangeLanguage from '../../components/change-language';
import ProgressBar from '../../components/progress-bar';

import { RootState } from '../../configStore';
import { useAppDispatch, useAppSelector } from '../../hooks';

import { Col, Row } from 'react-bootstrap';

import { Guest } from '../../interfaces/reservation.interface';
import {
  postReservation,
  setReservation,
} from '../../reducers/reservation.reducer';
import { formatterCPF, formatterOnlyLetter } from '../../utils/formatter';
import { isCPF, isEmail, isEmpty } from '../../utils/validate';

const initialErros: any[] = [];

const CompanionScreen = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const reservation = useAppSelector(
    (state: RootState) => state.reservation.data,
  );

  const successed = useAppSelector(
    (state: RootState) => state.reservation.successed,
  );

  const error = useAppSelector((state: RootState) => state.reservation.error);

  const [companionList, setCompanionList] = useState<Guest[]>([]);

  useEffect(() => {
    reservation.companionList &&
      setCompanionList(reservation.companionList || []);
  }, [reservation]);

  useEffect(() => {
    successed && navigate(`/${reservation.id}/success`);
  }, [navigate, reservation.id, successed]);

  useEffect(() => {
    error && alert(t('common.error'));
  }, [error, t]);

  const [errors, setErrors] = useState(initialErros);

  const validate = () => {
    let hasError = false;
    const _errors = [...initialErros];
    companionList.map((item) => {
      const _error = {
        name: {
          required: false,
        },
        lastname: {
          required: false,
        },
        email: {
          required: false,
          isInvalid: false,
        },
        documentType: {
          required: false,
        },
        document: {
          required: false,
          isInvalid: false,
        },
      };
      if (isEmpty(item.name)) {
        _error.name.required = true;
        hasError = true;
      }
      if (isEmpty(item.lastname)) {
        _error.lastname.required = true;
        hasError = true;
      }
      if (isEmpty(item.email)) {
        _error.email.required = true;
        hasError = true;
      } else if (!isEmail(item.email)) {
        _error.email.isInvalid = true;
        hasError = true;
      }
      if (isEmpty(item.documentType)) {
        _error.documentType.required = true;
        hasError = true;
      }
      if (isEmpty(item.document)) {
        _error.document.required = true;
        hasError = true;
      } else if (!isCPF(item.document)) {
        _error.document.isInvalid = true;
        hasError = true;
      }
      _errors.push(_error);
    });

    setErrors(_errors);
    return !hasError;
  };

  const handleSubmit = () => {
    if (validate()) {
      const _companionList: Guest[] = [];
      companionList.map((item) => {
        if (item.address && reservation.guest && reservation.guest.address) {
          const _companion = {
            ...item,
            fullName: `${item.name} ${item.lastname}`,
            address: reservation.guest.address,
            occupation: '',
            ddd: '',
            phoneNumber: '',
            gender: '',
            birthDate: '',
          };
          _companionList.push(_companion);
        }
      });
      try {
        const _reservation = { ...reservation, companionList: _companionList };
        dispatch(setReservation(_reservation));
        dispatch(postReservation(_reservation));
      } catch (error) {}
    }
  };

  // const addCompanion = () => {
  //   const _companionList = [...companionList];
  //   _companionList.push({
  //     id: 0,
  //     address: {
  //       city: '',
  //       complement: '',
  //       country: '',
  //       neighborhood: '',
  //       number: '',
  //       street: '',
  //       uf: '',
  //       zipcode: '',
  //     },
  //     name: '',
  //     lastname: '',
  //     fullName: '',
  //     birthDate: '',
  //     occupation: '',
  //     email: '',
  //     ddd: '',
  //     phoneNumber: '',
  //     gender: '',
  //     documentType: '',
  //     document: '',
  //   });
  //   setCompanionList(_companionList);
  // };

  return (
    <Container>
      <br />
      <ProgressBar
        color={reservation.hotel.color}
        title={t('companions.title')}
        step={5}
      />
      <br />
      <Card
        title={t('identification.title')}
        checked={true}
        first={true}
        path={`/${reservation.id}/identification`}
      >
        <Card
          title={t('personalInformation.title')}
          checked={true}
          path={`/${reservation.id}/personal-information`}
        >
          <Card
            title={t('emailConfirmation.title')}
            checked={true}
            path={`/${reservation.id}/email-confirmation`}
          >
            <Card
              title={t('additionalInformation.title')}
              checked={true}
              path={`/${reservation.id}/additional-information`}
            >
              <Card
                title={t('companions.title')}
                caption={
                  companionList.length > 0
                    ? t('companions.caption')
                    : t('companions.captionNoFound')
                }
              >
                <>
                  {companionList.map((item, index) => (
                    <Row key={index}>
                      <FormGroup className="mb-3">
                        <FormLabel>{t('companions.name')}</FormLabel>
                        <FormControl
                          name="name"
                          value={
                            item.name ? formatterOnlyLetter(item.name) : ''
                          }
                          onChange={(e: any) => {
                            const _companionList = [...companionList];
                            _companionList[index] = {
                              ..._companionList[index],
                              name: formatterOnlyLetter(e.target.value),
                            };
                            setCompanionList(_companionList);
                            const _errors = [...errors];
                            _errors[index] = {
                              ..._errors[index],
                              name: { require: false },
                            };
                            setErrors(_errors);
                          }}
                          isInvalid={
                            errors[index] &&
                            errors[index].name &&
                            errors[index].name.required
                          }
                          maxLength={40}
                        />
                        <FormControl.Feedback type="invalid">
                          {errors[index] &&
                            errors[index].name &&
                            errors[index].name.required &&
                            t('companions.errors.name.required')}
                        </FormControl.Feedback>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <FormLabel>{t('companions.lastName')}</FormLabel>
                        <FormControl
                          name="lastname"
                          value={
                            item.name ? formatterOnlyLetter(item.lastname) : ''
                          }
                          onChange={(e: any) => {
                            const _companionList = [...companionList];
                            _companionList[index] = {
                              ..._companionList[index],
                              lastname: formatterOnlyLetter(e.target.value),
                            };
                            setCompanionList(_companionList);
                            const _errors = [...errors];
                            _errors[index] = {
                              ..._errors[index],
                              lastname: { require: false },
                            };
                            setErrors(_errors);
                          }}
                          isInvalid={
                            errors[index] &&
                            errors[index].lastname &&
                            errors[index].lastname.required
                          }
                          maxLength={39}
                        />
                        <FormControl.Feedback type="invalid">
                          {errors[index] &&
                            errors[index].lastname &&
                            errors[index].lastname.required &&
                            t('companions.errors.lastName.required')}
                        </FormControl.Feedback>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <FormLabel>{t('companions.email')}</FormLabel>
                        <FormControl
                          name="email"
                          type="email"
                          value={item.email || ''}
                          onChange={(e: any) => {
                            const _companionList = [...companionList];
                            _companionList[index] = {
                              ..._companionList[index],
                              email: e.target.value,
                            };
                            setCompanionList(_companionList);
                            const _errors = [...errors];
                            _errors[index] = {
                              ..._errors[index],
                              email: { require: false },
                            };
                            setErrors(_errors);
                          }}
                          isInvalid={
                            errors[index] &&
                            errors[index].email &&
                            (errors[index].email.required ||
                              errors[index].email.isInvalid)
                          }
                        />
                        <FormControl.Feedback type="invalid">
                          {errors[index] &&
                            errors[index].email &&
                            errors[index].email.required &&
                            t('companions.errors.email.required')}
                          {errors[index] &&
                            errors[index].email &&
                            errors[index].email.isInvalid &&
                            t('companions.errors.email.isInvalid')}
                        </FormControl.Feedback>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <FormLabel>{t('companions.documentType')}</FormLabel>
                        <FormSelect
                          value={item.documentType || ''}
                          onChange={(e: any) => {
                            const _companionList = [...companionList];
                            _companionList[index] = {
                              ..._companionList[index],
                              documentType: e.target.value,
                            };
                            setCompanionList(_companionList);
                            const _errors = [...errors];
                            _errors[index] = {
                              ..._errors[index],
                              documentType: { require: false },
                            };
                            setErrors(_errors);
                          }}
                          isInvalid={
                            errors[index] &&
                            errors[index].documentType &&
                            errors[index].documentType.required
                          }
                        >
                          <option value="">{t('common.select')}</option>
                          <option value="CPF">CPF</option>
                        </FormSelect>
                        <FormControl.Feedback type="invalid">
                          {errors[index] &&
                            errors[index].documentType &&
                            errors[index].documentType.required &&
                            t('companions.errors.documentType.required')}
                        </FormControl.Feedback>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <FormLabel>{t('companions.document')}</FormLabel>
                        <FormControl
                          name="document"
                          type="tel"
                          value={item.document || ''}
                          onChange={(e: any) => {
                            const _companionList = [...companionList];
                            _companionList[index] = {
                              ..._companionList[index],
                              document: formatterCPF(e.target.value),
                            };
                            setCompanionList(_companionList);
                          }}
                          isInvalid={
                            errors[index] &&
                            errors[index].document &&
                            (errors[index].document.required ||
                              errors[index].document.isInvalid)
                          }
                        />
                        <FormControl.Feedback type="invalid">
                          {errors[index] &&
                            errors[index].document &&
                            errors[index].document.required &&
                            t('companions.errors.document.required')}
                          {errors[index] &&
                            errors[index].document &&
                            errors[index].document.isInvalid &&
                            t('companions.errors.document.isInvalid')}
                        </FormControl.Feedback>
                      </FormGroup>
                      <Separator />
                    </Row>
                  ))}
                </>
                {/* <Button
                  backgroundColor="#EEEEEE"
                  icon={<PlusSvg />}
                  text={t("companions.addCompanion")}
                  textColor="#505050"
                  onClick={addCompanion}
                /> */}
                {/* <br /> */}
                {/* <FormGroup className="mb-3">
                  <FormCheck
                    type="checkbox"
                    label="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Diam elit pellentesque adipiscing elit amet."
                  />
                </FormGroup>
                <br /> */}
                <Button
                  backgroundColor={reservation.hotel.color}
                  text={t('common.finish')}
                  onClick={handleSubmit}
                />
                <Row className="d-block d-sm-none">
                  <Col>
                    <br />
                    <ChangeLanguage backgroundColor={'#EEEEEE'} />
                  </Col>
                </Row>
              </Card>
            </Card>
          </Card>
        </Card>
      </Card>
    </Container>
  );
};

export default CompanionScreen;
