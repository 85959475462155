import { Address } from '../interfaces/address.interface';
import { City } from '../interfaces/city.interface';
import { Country } from '../interfaces/country.interface';
import { State } from '../interfaces/state.interface';

import API from './api';

export const fetchAddress = async (zipcode: string): Promise<Address> => {
  const res = await API.get(`/address/address/${zipcode}`);
  return res.data;
};

export const fetchCountries = async (): Promise<Country[]> => {
  const res = await API.get(`/address/country`);
  return res.data;
};

export const fetchStates = async (countryId: number): Promise<State[]> => {
  const res = await API.get(`/address/country/${countryId}/state`);
  return res.data;
};

export const fetchCities = async (stateId: number): Promise<City[]> => {
  const res = await API.get(`/address/state/${stateId}/city`);
  return res.data;
};

const funcs = {
  fetchAddress,
  fetchCountries,
  fetchStates,
  fetchCities,
};

export default funcs;
