import styled from 'styled-components';

// export const Container = styled.div`
//   display: flex;
//   flex-wrap: wrap;
//   width: 100%;
//   gap: 1%;
// `;

export const Container = styled.button<{
  backgroundColor: string;
}>`
  width: 100%;
  height: 40px;
  background-color: ${(props) => props.backgroundColor};
  box-shadow: 0px 4px 0px 0px #00000026;
  border-radius: 6px;
  border: 0;
  display: flex;
  align-items: center;
  padding: 0 13px;
`;

export const TextContainer = styled.div`
  width: 100%;
`;
