import { Text } from '../../styles';
import { Container, Step, StepContainer } from './styles';

type Props = {
  color?: string;
  title: string;
  step: number;
};

const ProgressBar = ({ color, title, step }: Props) => (
  <Container>
    {[1, 2, 3, 4, 5].map((current, index) => (
      <StepContainer key={index} width={current === step ? '50%' : '10%'}>
        <Step color={current <= step ? color || '#0F1A33' : '#C4C4C4'} />
        {current === step && (
          <Text size={12} weight={400} color="#505050">
            {title}
          </Text>
        )}
      </StepContainer>
    ))}
  </Container>
);

export default ProgressBar;
