import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import {
  Container,
  FormControl,
  FormGroup,
  FormLabel,
  Separator,
} from '../../styles';

import Button from '../../components/button';
import Card from '../../components/card';
import ChangeLanguage from '../../components/change-language';
import ProgressBar from '../../components/progress-bar';

import { Col, Row } from 'react-bootstrap';
import Select from 'react-select';
import { RootState } from '../../configStore';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { City } from '../../interfaces/city.interface';
import { Country } from '../../interfaces/country.interface';
import { State } from '../../interfaces/state.interface';
import { setLoading, setReservation } from '../../reducers/reservation.reducer';
import {
  fetchAddress,
  fetchCities,
  fetchCountries,
  fetchStates,
} from '../../services/address.service';
import { formatterCEP, formatterOnlyNumber } from '../../utils/formatter';

const removeAccents = (str: string) =>
  str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

const initialErros = {
  country: {
    required: false,
  },
  zipcode: {
    required: false,
  },
  uf: {
    required: false,
  },
  city: {
    required: false,
  },
  neighborhood: {
    required: false,
  },
  street: {
    required: false,
  },
  number: {
    required: false,
  },
};

const AdditionalInformationScreen = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const reservation = useAppSelector(
    (state: RootState) => state.reservation.data,
  );

  const [countries, setCountries] = useState<Country[]>([]);
  const [states, setStates] = useState<State[]>([]);
  const [cities, setCities] = useState<City[]>([]);

  const [countryOptions, setCountryOptions] = useState<any[]>([]);
  const [stateOptions, setStateOptions] = useState<any[]>([]);
  const [cityOptions, setCityOptions] = useState<any[]>([]);

  const [zipcode, setZipcode] = useState('');
  const [country, setCountry] = useState<Country | undefined>();
  const [uf, setUF] = useState<State | undefined>();
  const [ufText, setUFText] = useState<string | undefined>();
  const [city, setCity] = useState<City | undefined>();
  const [cityText, setCityText] = useState<string | undefined>();
  const [neighborhood, setNeighborhood] = useState('');
  const [street, setStreet] = useState('');
  const [number, setNumber] = useState('');
  const [complement, setComplement] = useState('');

  useEffect(() => {
    const load = async () => {
      try {
        dispatch(setLoading(true));
        const _countries = await fetchCountries();
        setUFText(reservation.guest.address.uf);
        setCityText(reservation.guest.address.city);
        if (_countries) {
          setCountries(_countries);
          const _country = _countries.find(
            (c) =>
              `${c.id}` === reservation.guest.address.country ||
              c.name === reservation.guest.address.country,
          );
          setCountry(_country);
          if (_country) {
            const _states = await fetchStates(_country.id);
            if (_states) {
              setStates(_states);
              const _state = _states.find(
                (c) =>
                  removeAccents(c.uf.toUpperCase()) ===
                    removeAccents(reservation.guest.address.uf.toUpperCase()) ||
                  removeAccents(c.name.toUpperCase()) ===
                    removeAccents(reservation.guest.address.uf.toUpperCase()),
              );
              setUF(_state);
              if (_state) {
                const _cities = await fetchCities(_state.id);
                if (_cities) {
                  setCities(_cities);
                  const _city = _cities.find(
                    (c) =>
                      removeAccents(`${c.id}`.toUpperCase()) ===
                        removeAccents(
                          `${reservation.guest.address.city}`.toUpperCase(),
                        ) ||
                      removeAccents(c.name.toUpperCase()) ===
                        removeAccents(
                          `${reservation.guest.address.city}`.toUpperCase(),
                        ),
                  );
                  setCity(_city);
                }
              }
            }
          }
        }
        setZipcode(reservation.guest.address.zipcode || '');
        setNeighborhood(
          reservation.guest.address.neighborhood.slice(0, 20) || '',
        );
        setStreet(reservation.guest.address.street.slice(0, 60) || '');
        setNumber(reservation.guest.address.number || '');
        setComplement(reservation.guest.address.complement || '');
      } catch (error) {
      } finally {
        dispatch(setLoading(false));
      }
    };
    load();
  }, [dispatch, reservation]);

  useEffect(() => {
    const options = countries.map((item) => ({
      value: item.id,
      label: item.name,
    }));
    setCountryOptions(options);
  }, [countries]);

  useEffect(() => {
    const options = states.map((item) => ({
      value: item.uf,
      label: item.name,
    }));

    setStateOptions(options);
  }, [states]);

  useEffect(() => {
    const options = cities.map((item) => ({
      value: item.id,
      label: item.name,
    }));
    setCityOptions(options);
  }, [cities]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      country,
      zipcode: zipcode || '',
      uf,
      ufText: ufText || '',
      city,
      cityText: cityText || '',
      neighborhood: neighborhood || '',
      street: street || '',
      number: number || '',
      complement: complement || '',
      submit: null,
    },
    validationSchema: Yup.object({
      country: Yup.object().required(
        t('additionalInformation.errors.country.required'),
      ),
      zipcode: Yup.string().when('country', (country, schema) => {
        return country[0] && country[0].id === 1
          ? schema.required(t('additionalInformation.errors.zipcode.required'))
          : schema.notRequired();
      }),
      uf: Yup.object().when('country', (country, schema) => {
        return country[0] && country[0].id === 1
          ? schema.required(t('additionalInformation.errors.uf.required'))
          : schema.notRequired();
      }),
      ufText: Yup.string().when('country', (country, schema) => {
        return country[0] && country[0].id !== 1
          ? schema.required(t('additionalInformation.errors.uf.required'))
          : schema.notRequired();
      }),
      city: Yup.object().when('country', (country, schema) => {
        return country[0] && country[0].id === 1
          ? schema.required(t('additionalInformation.errors.city.required'))
          : schema.notRequired();
      }),
      cityText: Yup.string().when('country', (country, schema) => {
        return country[0] && country[0].id !== 1
          ? schema.required(t('additionalInformation.errors.city.required'))
          : schema.notRequired();
      }),
      neighborhood: Yup.string().when('country', (country, schema) => {
        return country[0]
          ? schema.required(
              t('additionalInformation.errors.neighborhood.required'),
            )
          : schema.notRequired();
      }),
      street: Yup.string().when('country', (country, schema) => {
        return country[0]
          ? schema.required(t('additionalInformation.errors.street.required'))
          : schema.notRequired();
      }),
      number: Yup.string().when('country', (country, schema) => {
        return country[0]
          ? schema.required(t('additionalInformation.errors.number.required'))
          : schema.notRequired();
      }),
    }),
    onSubmit: async (values, helpers) => {
      try {
        const {
          country,
          zipcode,
          uf,
          ufText,
          city,
          cityText,
          neighborhood,
          street,
          number,
          complement,
        } = values;
        const data = {
          ...reservation,
          guest: {
            ...reservation.guest,
            address: {
              ...reservation.guest.address,
              country: (country && `${country.id}`) ?? '',
              zipcode: zipcode.replace(/[^\d]+/g, ''),
              uf: (uf && uf.uf) ?? ufText ?? '',
              city: (city && city.name) ?? cityText ?? '',
              neighborhood: neighborhood.slice(0, 20),
              street: street.slice(0, 60),
              number,
              complement,
            },
          },
        };
        dispatch(setReservation(data));
        navigate(`/${reservation.id}/companion`);
      } catch (error: any) {
        let message = 'Ops! Algo de errado aconteceu';
        if (
          error.response &&
          error.response.data &&
          error.response.data.message[0]
        ) {
          message = error.response.data.message.join('\r\n');
        }
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: message });
        helpers.setSubmitting(false);
      }
    },
  });

  const onBlurZipCode = async () => {
    if (formik.values.zipcode.length === 9) {
      dispatch(setLoading(true));
      formik.setFieldValue('uf', undefined);
      formik.setFieldValue('ufText', undefined);
      formik.setFieldValue('city', undefined);
      formik.setFieldValue('cityText', undefined);
      formik.setFieldValue('neighborhood', '');
      formik.setFieldValue('street', '');
      formik.setFieldValue('number', '');
      formik.setFieldValue('complement', '');
      const address = await fetchAddress(
        formik.values.zipcode.replace('-', ''),
      );
      if (address && address.uf) {
        const _states = await fetchStates(1);
        setStates(_states);
        const _state = _states.find(
          (c) =>
            removeAccents(c.uf.trim().toLowerCase()) ===
            removeAccents(address.uf.trim().toLowerCase()),
        );
        formik.setFieldValue('uf', _state);
        if (_state) {
          const _cities = await fetchCities(_state.id);
          setCities(_cities);
          const _city = _cities.find(
            (c) =>
              removeAccents(c.name.trim().toLowerCase()) ===
              removeAccents(address.city.trim().toLowerCase()),
          );
          formik.setFieldValue('city', _city);
        }
        formik.setFieldValue('neighborhood', address.neighborhood.slice(0, 20));
        formik.setFieldValue(
          'street',
          `${address.type} ${address.street}`.slice(0, 60),
        );
      }
      dispatch(setLoading(false));
    }
  };

  const onCountryChange = async (e: any) => {
    try {
      dispatch(setLoading(true));
      const _country = countries.find((c) => c.id === e.value);
      formik.setFieldValue('country', _country);
      formik.setFieldValue('zipcode', '');
      formik.setFieldValue('uf', undefined);
      formik.setFieldValue('ufText', undefined);
      formik.setFieldValue('city', undefined);
      formik.setFieldValue('cityText', undefined);
      formik.setFieldValue('neighborhood', '');
      formik.setFieldValue('street', '');
      formik.setFieldValue('number', '');
      formik.setFieldValue('complement', '');
      setStates([]);
      setStateOptions([]);
      setCities([]);
      setCityOptions([]);
      if (_country) {
        const _states = await fetchStates(_country.id);
        setStates(_states);
      }
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  };

  const onStateChange = async (e: any) => {
    try {
      dispatch(setLoading(true));
      const _state = states.find((c) => c.uf === e.value);
      formik.setFieldValue('uf', _state);
      formik.setFieldValue('ufText', undefined);
      formik.setFieldValue('city', undefined);
      formik.setFieldValue('cityText', undefined);
      setCities([]);
      setCityOptions([]);
      if (_state) {
        const _cities = await fetchCities(_state.id);
        setCities(_cities);
      }
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  };

  const onCityChange = (e: any) => {
    const _city = cities.find((c) => c.id === e.value);
    formik.setFieldValue('city', _city);
    formik.setFieldValue('cityText', undefined);
  };

  return (
    <Container>
      <br />
      <ProgressBar
        color={reservation.hotel.color}
        title={t('additionalInformation.title')}
        step={4}
      />
      <br />
      <Card
        title={t('identification.title')}
        checked={true}
        first={true}
        path={`/${reservation.id}/identification`}
      >
        <Card
          title={t('personalInformation.title')}
          checked={true}
          path={`/${reservation.id}/personal-information`}
        >
          <Card
            title={t('emailConfirmation.title')}
            checked={true}
            path={`/${reservation.id}/email-confirmation`}
          >
            <Card
              title={t('additionalInformation.title')}
              caption={t('additionalInformation.caption')}
            >
              <form noValidate onSubmit={formik.handleSubmit}>
                <FormGroup className="mb-3">
                  <FormLabel>{t('additionalInformation.country')}</FormLabel>
                  <Select
                    name="country"
                    value={
                      formik.values.country
                        ? {
                            label: formik.values.country.name,
                            value: formik.values.country.id,
                          }
                        : undefined
                    }
                    onBlur={formik.handleBlur}
                    onChange={onCountryChange}
                    placeholder={t('common.select')}
                    options={countryOptions}
                    className={
                      !!(formik.touched.country && formik.errors.country)
                        ? 'is-invalid'
                        : undefined
                    }
                    styles={
                      !!(formik.touched.country && formik.errors.country)
                        ? {
                            control: (base) => ({
                              ...base,
                              borderColor: 'red',
                            }),
                          }
                        : undefined
                    }
                  />
                  <FormControl.Feedback type="invalid">
                    {formik.touched.country && formik.errors.country}
                  </FormControl.Feedback>
                </FormGroup>
                <FormGroup className="mb-3">
                  <FormLabel>{t('additionalInformation.zipcode')}</FormLabel>
                  <FormControl
                    type="text"
                    name="zipcode"
                    value={formik.values.zipcode}
                    onBlur={(e: any) => {
                      formik.handleBlur(e);
                      formik.values.country && formik.values.country.id === 1
                        ? onBlurZipCode()
                        : undefined;
                    }}
                    onChange={(e: any) => {
                      formik.setFieldValue(
                        'zipcode',
                        formik.values.country && formik.values.country.id === 1
                          ? formatterCEP(e.target.value)
                          : formatterOnlyNumber(e.target.value),
                      );
                    }}
                    isInvalid={
                      !!(formik.touched.zipcode && formik.errors.zipcode)
                    }
                  />
                  <FormControl.Feedback type="invalid">
                    {formik.touched.zipcode && formik.errors.zipcode}
                  </FormControl.Feedback>
                </FormGroup>
                <Separator />
                <FormGroup className="mb-3">
                  <FormLabel>{t('additionalInformation.uf')}</FormLabel>
                  {formik.values.country && formik.values.country.id === 1 ? (
                    <>
                      <Select
                        name="uf"
                        value={
                          formik.values.uf
                            ? {
                                label: formik.values.uf.name,
                                value: formik.values.uf.id,
                              }
                            : undefined
                        }
                        onBlur={formik.handleBlur}
                        onChange={onStateChange}
                        placeholder={t('common.select')}
                        options={stateOptions.sort((a, b) =>
                          a.label > b.label ? 1 : b.label > a.label ? -1 : 0,
                        )}
                        className={
                          !!(formik.touched.uf && formik.errors.uf)
                            ? 'is-invalid'
                            : undefined
                        }
                        styles={
                          !!(formik.touched.uf && formik.errors.uf)
                            ? {
                                control: (base) => ({
                                  ...base,
                                  borderColor: 'red',
                                }),
                              }
                            : undefined
                        }
                        isDisabled={true}
                      />
                      <FormControl.Feedback type="invalid">
                        {formik.touched.uf && formik.errors.uf}
                      </FormControl.Feedback>
                    </>
                  ) : (
                    <>
                      <FormControl
                        type="text"
                        name="ufText"
                        value={formik.values.ufText}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        isInvalid={
                          !!(formik.touched.ufText && formik.errors.ufText)
                        }
                        maxLength={20}
                      />
                      <FormControl.Feedback type="invalid">
                        {formik.touched.ufText && formik.errors.ufText}
                      </FormControl.Feedback>
                    </>
                  )}
                </FormGroup>
                <FormGroup className="mb-3">
                  <FormLabel>{t('additionalInformation.city')}</FormLabel>
                  {formik.values.country && formik.values.country.id === 1 ? (
                    <>
                      <Select
                        name="city"
                        value={
                          formik.values.city
                            ? {
                                label: formik.values.city.name,
                                value: formik.values.city.id,
                              }
                            : {}
                        }
                        onBlur={formik.handleBlur}
                        onChange={onCityChange}
                        placeholder={t('common.select')}
                        options={cityOptions.sort((a, b) =>
                          a.label > b.label ? 1 : b.label > a.label ? -1 : 0,
                        )}
                        className={
                          !!(formik.touched.city && formik.errors.city)
                            ? 'is-invalid'
                            : undefined
                        }
                        styles={
                          !!(formik.touched.city && formik.errors.city)
                            ? {
                                control: (base) => ({
                                  ...base,
                                  borderColor: 'red',
                                }),
                              }
                            : undefined
                        }
                        isDisabled={true}
                      />
                      <FormControl.Feedback type="invalid">
                        {formik.touched.city && formik.errors.city}
                      </FormControl.Feedback>
                    </>
                  ) : (
                    <>
                      <FormControl
                        type="text"
                        name="cityText"
                        value={formik.values.cityText}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        isInvalid={
                          !!(formik.touched.cityText && formik.errors.cityText)
                        }
                        maxLength={20}
                      />
                      <FormControl.Feedback type="invalid">
                        {formik.touched.cityText && formik.errors.cityText}
                      </FormControl.Feedback>
                    </>
                  )}
                </FormGroup>
                <FormGroup className="mb-3">
                  <FormLabel>
                    {t('additionalInformation.neighborhood')}
                  </FormLabel>
                  <FormControl
                    type="text"
                    name="neighborhood"
                    value={formik.values.neighborhood}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    isInvalid={
                      !!(
                        formik.touched.neighborhood &&
                        formik.errors.neighborhood
                      )
                    }
                    maxLength={20}
                    disabled={
                      formik.values.country && formik.values.country.id === 1
                    }
                  />
                  <FormControl.Feedback type="invalid">
                    {formik.touched.neighborhood && formik.errors.neighborhood}
                  </FormControl.Feedback>
                </FormGroup>
                <FormGroup className="mb-3">
                  <FormLabel>{t('additionalInformation.street')}</FormLabel>
                  <FormControl
                    type="text"
                    name="street"
                    value={formik.values.street}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    isInvalid={
                      !!(formik.touched.street && formik.errors.street)
                    }
                    maxLength={60}
                    disabled={
                      formik.values.country && formik.values.country.id === 1
                    }
                  />
                  <FormControl.Feedback type="invalid">
                    {formik.touched.street && formik.errors.street}
                  </FormControl.Feedback>
                </FormGroup>
                <FormGroup className="mb-3">
                  <FormLabel>{t('additionalInformation.number')}</FormLabel>
                  <FormControl
                    type="text"
                    name="number"
                    value={formik.values.number}
                    onBlur={formik.handleBlur}
                    onChange={(e: any) => {
                      formik.setFieldValue(
                        'number',
                        formatterOnlyNumber(e.target.value),
                      );
                    }}
                    isInvalid={
                      !!(formik.touched.number && formik.errors.number)
                    }
                    maxLength={6}
                  />
                  <FormControl.Feedback type="invalid">
                    {formik.touched.number && formik.errors.number}
                  </FormControl.Feedback>
                </FormGroup>
                <FormGroup className="mb-3">
                  <FormLabel style={{ fontWeight: 'normal' }}>
                    {t('additionalInformation.complement')}
                  </FormLabel>
                  <FormControl
                    type="text"
                    name="complement"
                    value={formik.values.complement}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    isInvalid={
                      !!(formik.touched.complement && formik.errors.complement)
                    }
                    maxLength={60}
                  />
                  <FormControl.Feedback type="invalid">
                    {formik.touched.complement && formik.errors.complement}
                  </FormControl.Feedback>
                </FormGroup>
                <br />
                <Button
                  type="submit"
                  backgroundColor={reservation.hotel.color}
                  text={t('common.continue')}
                />
                <Row className="d-block d-sm-none">
                  <Col>
                    <br />
                    <ChangeLanguage backgroundColor={'#EEEEEE'} />
                  </Col>
                </Row>
              </form>
            </Card>
          </Card>
        </Card>
      </Card>
    </Container>
  );
};

export default AdditionalInformationScreen;
