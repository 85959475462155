import styled from 'styled-components';

export const Container = styled.div<{
  checked?: boolean;
  first?: boolean;
}>`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: ${(props) => (props.checked ? '#D2D2D2' : '#FFFFFF')};
  border-radius: 20px;
  ${(props) =>
    props.checked && !props.first && 'box-shadow: 0px -2px 3px 0px #00000026;'}
  ${(props) => !props.checked && 'box-shadow: 0px -4px 20px 0px #0000001A;'}
`;

export const Title = styled.div`
  padding: 15px 25px;
  display: flex;
  width: 100%;
  align-items: center;
`;

export const Content = styled.div<{
  checked?: boolean;
}>`
  ${(props) => !props.checked && 'padding: 20px;'}
`;
