import { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ReactComponent as CheckinSvg } from './assets/svg/checkin.svg';
import { ReactComponent as CheckoutSvg } from './assets/svg/checkout.svg';
import { Image, Text } from './styles';

import AdditionalInformationScreen from './screens/additional-information';
import CompanionScreen from './screens/companion';
import EmailConfirmationScreen from './screens/email-confirmation';
import HomeScreen from './screens/home';
import IdentificationScreen from './screens/identification';
import PersonalInformationScreen from './screens/personal-information';
import SuccessScreen from './screens/success';

import { RootState } from './configStore';
import { useAppDispatch, useAppSelector } from './hooks';
import { fetchReservation } from './reducers/reservation.reducer';

import moment from 'moment';

import { useTranslation } from 'react-i18next';
import './App.css';
import ChangeLanguage from './components/change-language';

import LoadingOverlay from 'react-loading-overlay';

import * as qs from 'qs';
import toast, { Toaster } from 'react-hot-toast';

function App() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const reservation = useAppSelector(
    (state: RootState) => state.reservation.data,
  );

  const loading = useAppSelector(
    (state: RootState) => state.reservation.loading,
  );

  const error = useAppSelector((state: RootState) => state.reservation.error);

  useEffect(() => {
    const domain = window.location.hostname;
    if (domain) {
      window.sessionStorage.setItem('domain', domain);
    } else {
      window.sessionStorage.removeItem('domain');
    }

    const email = qs
      .parse(window.location.search, {
        ignoreQueryPrefix: true,
      })
      .email?.toString();

    if (email) {
      window.sessionStorage.setItem('email', email);
    } else {
      window.sessionStorage.removeItem('email');
    }

    const params = window.location.pathname.split('/');
    params[1] &&
      Number(params[1]) &&
      dispatch(fetchReservation({ id: Number(params[1]) }));
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      toast.error(error.message || t('common.error'));
    }
  }, [error]);

  useEffect(() => {
    document.title = reservation.hotel.name;
  });

  return (
    <div className="App">
      <LoadingOverlay
        active={loading}
        spinner
        styles={{
          wrapper: (base) => ({
            ...base,
            overflow: loading ? 'hidden' : 'scroll',
            position: 'fixed',
            width: '100%',
            height: '100%',
            zIndex: 999,
            display: loading ? 'block' : 'none',
          }),
          content: (base) => ({
            ...base,
          }),
        }}
      />
      {reservation.id !== 0 && (
        <>
          <div
            className="App-pre-header"
            style={{
              backgroundColor: reservation.hotel.color,
            }}
          >
            <Text size={14} weight={700} color={'#FFFFFF'}>
              PRÉ CHECK-IN
            </Text>
          </div>
          <br />
          <br />
          <Container className="App-container flex-grow-1">
            <Row style={{ flex: 1 }}>
              <Col xs={12} md={4}>
                <div className="App-title">
                  <div style={{ textAlign: 'center' }}>
                    {reservation.hotel && (
                      <Image
                        fluid={true}
                        src={reservation.hotel.logo}
                        width="80%"
                      />
                    )}
                  </div>
                  <br />
                  <Text
                    size={16}
                    weight={400}
                    color={'#505050'}
                    lineHeight={19}
                  >
                    {t('common.reservation')} <strong>#{reservation.id}</strong>
                  </Text>
                  <br />
                  <div
                    style={{
                      display: 'flex',
                      height: 20,
                      alignItems: 'center',
                      marginBottom: 5,
                    }}
                  >
                    <CheckinSvg style={{ marginRight: 5 }} />
                    <Text
                      size={16}
                      weight={400}
                      color={'#505050'}
                      lineHeight={19}
                    >
                      Check-in:{' '}
                      {reservation.checkIn &&
                        moment
                          .parseZone(reservation.checkIn)
                          .utc(true)
                          .format('DD/MM/YYYY')}
                    </Text>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      height: 20,
                      alignItems: 'center',
                    }}
                  >
                    <CheckoutSvg style={{ marginRight: 5 }} />
                    <Text
                      size={16}
                      weight={400}
                      color={'#505050'}
                      lineHeight={19}
                    >
                      Check-out:{' '}
                      {reservation.checkOut &&
                        moment
                          .parseZone(reservation.checkOut)
                          .utc(true)
                          .format('DD/MM/YYYY')}
                    </Text>
                  </div>
                  <Row className="d-none d-md-block ">
                    <Col>
                      <br />
                      <ChangeLanguage backgroundColor={'#FFFFFF'} />
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col xs={12} md={8}>
                <div className="App-card">
                  <BrowserRouter>
                    <Routes>
                      <Route
                        path="/:id/identification"
                        element={<IdentificationScreen />}
                      />
                      <Route
                        path="/:id/personal-information"
                        element={<PersonalInformationScreen />}
                      />
                      <Route
                        path="/:id/email-confirmation"
                        element={<EmailConfirmationScreen />}
                      />
                      <Route
                        path="/:id/additional-information"
                        element={<AdditionalInformationScreen />}
                      />
                      <Route
                        path="/:id/companion"
                        element={<CompanionScreen />}
                      />
                      <Route path="/:id/success" element={<SuccessScreen />} />
                      <Route path="/:id" element={<HomeScreen />} />
                    </Routes>
                  </BrowserRouter>
                </div>
              </Col>
            </Row>
          </Container>
          <footer
            className="App-footer"
            style={{
              backgroundColor: reservation.hotel.color,
            }}
          >
            <Text size={11} weight={400} color={'#FFFFFF'}>
              Central de atendimento
              <br />
              0800 600 8088 | reservas@grupowish.com
            </Text>
          </footer>
        </>
      )}
      <Toaster />
    </div>
  );
}

export default App;
