import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 80px;
  justify-content: center;
`;

export const Card = styled.div<{
  backgroundColor: string;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 172px;
  height: 80px;
  background-color: ${(props) => props.backgroundColor};
  border-radius: 15px;
`;

export const LangContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 10px;
`;

export const LangButton = styled.button`
  border: 0;
  background-color: transparent;
`;
