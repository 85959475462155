import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { State } from '../interfaces/state.interface';

import addressService from '../services/address.service';

interface StateState {
  data: State[];
  loading: boolean;
}

const initialState: StateState = {
  data: [],
  loading: false,
};

export const fetchStates = createAsyncThunk(
  'states/fetchStates',
  async (countryId: number): Promise<State[]> => {
    return addressService.fetchStates(countryId);
  },
);

const slice = createSlice({
  name: 'states',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStates.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchStates.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(fetchStates.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default slice.reducer;
