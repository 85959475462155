import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Country } from '../interfaces/country.interface';

import addressService from '../services/address.service';

interface CounrtyState {
  data: Country[];
  loading: boolean;
}

const initialState: CounrtyState = {
  data: [],
  loading: false,
};

export const fetchCountries = createAsyncThunk(
  'country/fetchCountries',
  async (): Promise<Country[]> => {
    return addressService.fetchCountries();
  },
);

const slice = createSlice({
  name: 'countries',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCountries.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCountries.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(fetchCountries.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default slice.reducer;
