import { Container, ImageHeader, Text } from '../../styles';

import Button from '../../components/button';
import Card from '../../components/card';

import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ChangeLanguage from '../../components/change-language';
import { RootState } from '../../configStore';
import { useAppSelector } from '../../hooks';

const SuccessScreen = () => {
  const openInNewTab = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const { t } = useTranslation();
  const reservation = useAppSelector(
    (state: RootState) => state.reservation.data,
  );
  return (
    <Container>
      <br />
      <Card title="">
        {reservation.hotel && <ImageHeader src={reservation.hotel.image} />}
        <br />
        <Text size={14} weight={500} color="#000000" lineHeight={16}>
          {t('success.title')}
          <br />
          <br />
          <Text size={12} weight={400} color="#000000" lineHeight={15}>
            {t('success.caption')}
          </Text>
        </Text>
        <br />
        <Button
          backgroundColor="#EEEEEE"
          text={t('success.discoverDestinations')}
          textColor="#505050"
          onClick={() =>
            openInNewTab('https://www.grupowish.com/nossas-marcas')
          }
        />
        <br />
        <Text size={12} weight={400} color="#000000" lineHeight={15}>
          {t('success.text')}
        </Text>
        <br />
        <Row className="d-block d-sm-none">
          <Col>
            <br />
            <ChangeLanguage backgroundColor={'#EEEEEE'} />
          </Col>
        </Row>
      </Card>
    </Container>
  );
};

export default SuccessScreen;
