const translations = {
  translations: {
    common: {
      changeLanguage: 'Cambiar idioma',
      continue: 'SEGUIR',
      finish: 'FINALIZAR',
      select: 'Seleccione...',
      error: '¡Ups! algo salió mal',
      reservation: 'Reserva',
    },
    home: {
      title:
        '¡Estimado huésped, ahorre tiempo! haga su pre check-in prontamente y gana más tiempo para disfrutar de tu estadía con nosotros.',
      caption:
        'Atención: verifique sus datos completos y dirección de correo electrónico.',
    },
    identification: {
      title: '1. Identificación',
      caption: 'Informe el documento de identidad del titular de la reserva',
      documentType: 'Tipo de documento',
      document: 'Número del documento',
      errors: {
        documentType: {
          required: 'El tipo de documento es un campo obligatorio',
        },
        document: {
          required: 'El número de documento es un campo obligatorio',
          isInvalid: 'El número de documento no es válido',
        },
      },
    },
    personalInformation: {
      title: '2. Informaciones personales',
      caption: 'Rellene los datos del titular de la reserva',
      name: 'Nombre',
      lastName: 'Apellido',
      birthDate: 'Fecha de nacimiento',
      email: 'Email',
      phoneNumber: 'Celular',
      genre: 'Género',
      nationality: 'Nacionalidad',
      occupation: 'Profesión',
      reason: 'Motivo del viaje',
      reasonLeisure: 'Ocio',
      reasonBusiness: 'Negocio',
      reasonOther: 'Otro',
      errors: {
        name: {
          required: 'El nombre es un campo obligatorio',
        },
        lastName: {
          required: 'El apellido es un campo obligatorio',
        },
        birthDate: {
          required: 'Fecha de nacimiento es un campo obligatorio',
          isInvalid: 'Fecha de nacimiento es invalido',
        },
        email: {
          required: 'Email es un campo obligatorio',
          isInvalid: 'Email es invalido',
        },
        phoneNumber: {
          required: 'Celular es un campo obligatorio',
          isInvalid: 'Celular es invalido',
        },
        gender: {
          required: 'El género es un campo obligatorio',
        },
        occupation: {
          required: 'Profesión es un campo obligatorio',
        },
      },
    },
    emailConfirmation: {
      title: '3. Confirmación de email',
      caption:
        'Incluya el código de confirmación recibido por correo electrónico.',
      text: '¿No recibiste confirmación? {{click}} para enviar un nuevo código al correo electrónico {{customer_email}}.',
      click: 'Haga clic aquí',
    },
    additionalInformation: {
      title: '4. Informaciones adicionales',
      caption: '',
      country: 'Pais',
      zipcode: 'Código postal',
      uf: 'Estado',
      city: 'Ciudad',
      neighborhood: 'Vecindario',
      street: 'Dirección',
      number: 'Número',
      complement: 'Complementar',
      errors: {
        country: {
          required: 'El país es un campo obligatorio',
        },
        zipcode: {
          required: 'El código postal es un campo obligatorio',
        },
        uf: {
          required: 'El estado es un campo obligatorio',
        },
        city: {
          required: 'La ciudad es un campo obligatorio',
        },
        neighborhood: {
          required: 'El vecindario es un campo obligatorio',
        },
        street: {
          required: 'La dirección es un campo obligatorio',
        },
        number: {
          required: 'El número es un campo obligatorio',
        },
      },
    },
    companions: {
      title: '5. Compañeros',
      caption: 'Incluya los datos de los demás huéspedes.',
      captionNoFound:
        'Reservas sin acompañante, haga clic abajo para finalizar.',
      name: 'Nombre',
      lastName: 'Apellido',
      fullName: 'Nombre completo',
      email: 'Correo electrónico',
      documentType: 'Tipo de documento',
      document: 'Número del documento',
      addCompanion: 'AÑADIR UN COMPAÑERO',
      errors: {
        name: {
          required: 'El nombre es un campo obligatorio',
        },
        lastName: {
          required: 'El apellido es un campo obligatorio',
        },
        fullName: {
          required: 'El nombre completo es un campo obligatorio',
        },
        email: {
          required: 'El correo electrónico es un campo obligatorio',
          isInvalid: 'El correo electrónico es invalido',
        },
        documentType: {
          required: 'El tipo de documento es un campo obligatorio',
        },
        document: {
          required: 'El número de documento es un campo obligatorio',
          isInvalid: 'El número de documento no es válido',
        },
      },
    },
    success: {
      title:
        'Ha completado con éxito su pre check-in. ¡Ahora informe a la recepción que el pre check-in ha finalizado y disfrute de su estadía!',
      caption:
        'Ahora simplemente informe en recepción que su pre check-in anticipado ha finalizado y disfrute de su estancia!',
      discoverDestinations: 'DESCUBRE NUESTROS OTROS DESTINOS',
      text: 'No se preocupe. Recibirá un correo electrónico de confirmación con la información. Si aún necesita hacer alguna corrección, podrá hacerlo en la recepción del hotel.',
    },
  },
};

export default translations;
