import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Container, FormControl, Text } from '../../styles';

import Button from '../../components/button';
import Card from '../../components/card';
import ChangeLanguage from '../../components/change-language';
import ProgressBar from '../../components/progress-bar';

import { useEffect, useState } from 'react';
import { RootState } from '../../configStore';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  sendVerificationCode,
  validateVerificationCode,
} from '../../reducers/reservation.reducer';
import { formatterOnlyNumber } from '../../utils/formatter';
import { isEmpty } from '../../utils/validate';

const initialErros = {
  digit1: {
    required: false,
  },
  digit2: {
    required: false,
  },
  digit3: {
    required: false,
  },
  digit4: {
    required: false,
  },
  digit5: {
    required: false,
  },
  digit6: {
    required: false,
  },
};

const EmailConfirmationScreen = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const reservation = useAppSelector(
    (state: RootState) => state.reservation.data,
  );

  const {
    id,
    guest: { name, email },
  } = reservation;

  const verified = useAppSelector(
    (state: RootState) => state.reservation.verified,
  );

  const [digit1, setDigit1] = useState('');
  const [digit2, setDigit2] = useState('');
  const [digit3, setDigit3] = useState('');
  const [digit4, setDigit4] = useState('');
  const [digit5, setDigit5] = useState('');
  const [digit6, setDigit6] = useState('');

  useEffect(() => {
    dispatch(sendVerificationCode({ id, name, email }));
  }, []);

  useEffect(() => {
    if (verified) {
      navigate(`/${reservation.id}/additional-information`);
    }
  }, [dispatch, navigate, reservation, verified]);

  const [errors, setErrors] = useState(initialErros);

  const validate = () => {
    let hasError = false;
    const _errors = { ...initialErros };
    if (isEmpty(digit1)) {
      _errors.digit1.required = true;
      hasError = true;
    }
    if (isEmpty(digit2)) {
      _errors.digit2.required = true;
      hasError = true;
    }
    if (isEmpty(digit3)) {
      _errors.digit3.required = true;
      hasError = true;
    }
    if (isEmpty(digit4)) {
      _errors.digit4.required = true;
      hasError = true;
    }
    if (isEmpty(digit5)) {
      _errors.digit5.required = true;
      hasError = true;
    }
    if (isEmpty(digit6)) {
      _errors.digit6.required = true;
      hasError = true;
    }
    setErrors(_errors);
    return !hasError;
  };

  const handleSubmit = () => {
    if (validate()) {
      const code = `${digit1}${digit2}${digit3}${digit4}${digit5}${digit6}`;
      dispatch(validateVerificationCode({ id: reservation.id, code }));
    }
  };

  const handleNextInput = (e: any) => {
    const { maxLength, value, name } = e.target;
    const [fieldName, fieldIndex] = name.split('-');
    if (value.length >= 1) {
      if (parseInt(fieldIndex, 10) < 6) {
        const nextSibling = document.querySelector(
          `input[name=digit-${parseInt(fieldIndex, 10) + 1}]`,
        );
        if (nextSibling) {
          (nextSibling as HTMLElement)?.focus();
        }
      }
    }
  };

  const resend = () => {
    dispatch(sendVerificationCode({ id, name, email }));
  };

  const emailConfirmationText = t('emailConfirmation.text').split('{{click}}');

  return (
    <Container>
      <br />
      <ProgressBar
        color={reservation.hotel.color}
        title={t('emailConfirmation.title')}
        step={3}
      />
      <br />
      <Card
        title={t('identification.title')}
        checked={true}
        first={true}
        path={`/${reservation.id}/identification`}
      >
        <Card
          title={t('personalInformation.title')}
          checked={true}
          path={`/${reservation.id}/personal-information`}
        >
          <Card
            title={t('emailConfirmation.title')}
            caption={t('emailConfirmation.caption')}
          >
            <Row style={{}}>
              <Col
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  gap: '2%',
                }}
              >
                <FormControl
                  name="digit-1"
                  value={digit1 || ''}
                  onChange={(e: any) => {
                    setDigit1(formatterOnlyNumber(e.target.value));
                    setErrors({
                      ...errors,
                      digit1: { required: false },
                    });
                    handleNextInput(e);
                  }}
                  isInvalid={errors.digit1 && errors.digit1.required}
                  maxLength="1"
                  style={{
                    width: '15%',
                    height: 70,
                    fontSize: 32,
                    textAlign: 'center',
                    color: '#000000',
                  }}
                />
                <FormControl
                  name="digit-2"
                  value={digit2}
                  onChange={(e: any) => {
                    setDigit2(formatterOnlyNumber(e.target.value));
                    setErrors({
                      ...errors,
                      digit2: { required: false },
                    });
                    handleNextInput(e);
                  }}
                  isInvalid={errors.digit2 && errors.digit2.required}
                  maxLength="1"
                  style={{
                    width: '15%',
                    height: 70,
                    fontSize: 32,
                    textAlign: 'center',
                    color: '#000000',
                  }}
                />
                <FormControl
                  name="digit-3"
                  value={digit3}
                  onChange={(e: any) => {
                    setDigit3(formatterOnlyNumber(e.target.value));
                    setErrors({
                      ...errors,
                      digit3: { required: false },
                    });
                    handleNextInput(e);
                  }}
                  isInvalid={errors.digit3 && errors.digit3.required}
                  maxLength="1"
                  style={{
                    width: '15%',
                    height: 70,
                    fontSize: 32,
                    textAlign: 'center',
                    color: '#000000',
                  }}
                />
                <FormControl
                  name="digit-4"
                  value={digit4}
                  onChange={(e: any) => {
                    setDigit4(formatterOnlyNumber(e.target.value));
                    setErrors({
                      ...errors,
                      digit4: { required: false },
                    });
                    handleNextInput(e);
                  }}
                  isInvalid={errors.digit4 && errors.digit4.required}
                  maxLength="1"
                  style={{
                    width: '15%',
                    height: 70,
                    fontSize: 32,
                    textAlign: 'center',
                    color: '#000000',
                  }}
                />
                <FormControl
                  name="digit-5"
                  value={digit5}
                  onChange={(e: any) => {
                    setDigit5(formatterOnlyNumber(e.target.value));
                    setErrors({
                      ...errors,
                      digit5: { required: false },
                    });
                    handleNextInput(e);
                  }}
                  isInvalid={errors.digit5 && errors.digit5.required}
                  maxLength="1"
                  style={{
                    width: '15%',
                    height: 70,
                    fontSize: 32,
                    textAlign: 'center',
                    color: '#000000',
                  }}
                />
                <FormControl
                  name="digit-6"
                  value={digit6}
                  onChange={(e: any) => {
                    setDigit6(formatterOnlyNumber(e.target.value));
                    setErrors({
                      ...errors,
                      digit6: { required: false },
                    });
                  }}
                  isInvalid={errors.digit6 && errors.digit6.required}
                  maxLength="1"
                  style={{
                    width: '15%',
                    height: 70,
                    fontSize: 32,
                    textAlign: 'center',
                    color: '#000000',
                  }}
                />
              </Col>
            </Row>
            <br />
            <Button
              backgroundColor={reservation.hotel.color}
              text={t('common.continue')}
              onClick={handleSubmit}
            />
            <br />
            <Text size={12} weight={400} color="#000000" lineHeight={15}>
              {emailConfirmationText[0]}{' '}
              <a
                href="#"
                title={t('emailConfirmation.click')}
                onClick={(e) => {
                  e.preventDefault();
                  resend();
                }}
              >
                {t('emailConfirmation.click')}
              </a>{' '}
              {reservation &&
                reservation.guest &&
                emailConfirmationText[1].replace(
                  '{{customer_email}}',
                  reservation.guest.email,
                )}
            </Text>
            <Row className="d-block d-sm-none">
              <Col>
                <br />
                <ChangeLanguage backgroundColor={'#EEEEEE'} />
              </Col>
            </Row>
          </Card>
        </Card>
      </Card>
    </Container>
  );
};

export default EmailConfirmationScreen;
