import { Text } from '../../styles';
import { Container, TextContainer } from './styles';

type Props = {
  type?: 'submit' | 'reset' | 'button' | undefined;
  backgroundColor?: string;
  icon?: any;
  text?: string;
  textColor?: string;
  onClick?: () => void;
};

const Button = ({
  type,
  backgroundColor,
  icon,
  text,
  textColor,
  onClick,
}: Props) => (
  <Container
    type={type}
    backgroundColor={backgroundColor || '#0F1A33'}
    onClick={onClick}
  >
    <>
      {icon && icon}
      <TextContainer>
        <Text size={14} weight={700} color={textColor || '#FFFFFF'}>
          {text}
        </Text>
      </TextContainer>
    </>
  </Container>
);

export default Button;
