export const formatterCPF = (value: string) => {
  value = value.substring(0, 14);
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  return value;
};

export const formatterCellPhone = (value: string) => {
  value = value.substring(0, 10);
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d)(\d{4})$/, '$1-$2');
  return value;
};

export const formatterCEP = (value: string) => {
  value = value.substring(0, 9);
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d)(\d{3})$/, '$1-$2');
  return value;
};

export const formatterOnlyNumber = (value: string) => {
  value = value.replace(/\D/g, '');
  return value;
};

export const formatterOnlyLetter = (value: string) => {
  value = value.replace(/\d+/g, '');
  return value;
};

export const formatterDate = (value: string) => {
  value = value.substring(0, 8);
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d{2})(\d{2})(\d{4})/, '$1/$2/$3');
  return value;
};
