import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Reason } from '../interfaces/reason.inferface';

import reasonService from '../services/reason.service';

interface ReasonState {
  data: Reason[];
  loading: boolean;
}

const initialState: ReasonState = {
  data: [],
  loading: false,
};

export const fetchReasons = createAsyncThunk(
  'reason/fetchReasons',
  async (): Promise<Reason[]> => {
    return reasonService.fetchReasons();
  },
);

const slice = createSlice({
  name: 'reason',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchReasons.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchReasons.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(fetchReasons.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default slice.reducer;
