import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Reservation } from '../interfaces/reservation.interface';
import { Result } from '../interfaces/result.interface';

import reservationService from '../services/reservation.service';

interface ReservationState {
  data: Reservation;
  error: any;
  loading: boolean;
  verified: boolean;
  successed: boolean;
}

const initialState: ReservationState = {
  data: {
    id: 0,
    hotel: {
      hotelId: 0,
      name: '',
      color: '',
      logo: '',
      image: '',
    },
    checkIn: '',
    checkOut: '',
    reasonId: '',
    guest: {
      id: 0,
      address: {
        uf: '',
        neighborhood: '',
        number: '',
        complement: '',
        street: '',
        city: '',
        country: '',
        zipcode: '',
      },
      name: '',
      lastname: '',
      fullName: '',
      birthDate: '',
      occupation: '',
      email: '',
      ddd: '',
      phoneNumber: '',
      gender: '',
      documentType: '',
      document: '',
    },
    companionList: [],
  },
  error: null,
  loading: false,
  verified: false,
  successed: false,
};

const slice = createSlice({
  name: 'reservation',
  initialState,
  reducers: {
    setReservation: (state, action: PayloadAction<Reservation>) => {
      state.data = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReservation.pending, (state) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(fetchReservation.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(fetchReservation.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(postReservation.pending, (state) => {
        state.error = null;
        state.loading = true;
        state.successed = false;
      })
      .addCase(postReservation.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
        state.successed = true;
      })
      .addCase(postReservation.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(sendVerificationCode.pending, (state) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(sendVerificationCode.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(sendVerificationCode.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(validateVerificationCode.pending, (state) => {
        state.error = null;
        state.loading = true;
        state.verified = false;
      })
      .addCase(validateVerificationCode.fulfilled, (state, action) => {
        state.verified = action.payload.status;
        state.loading = false;
      })
      .addCase(validateVerificationCode.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export const { setLoading, setReservation } = slice.actions;

export const fetchReservation = createAsyncThunk(
  'reservation/fetchReservation',
  async (
    data: { id: number },
    { rejectWithValue },
  ): Promise<Reservation | any> => {
    try {
      return await reservationService.fetchReservation(data);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const postReservation = createAsyncThunk(
  'reservation/postReservationaaa',
  async (
    data: Reservation,
    { rejectWithValue },
  ): Promise<Reservation | any> => {
    try {
      return await reservationService.postReservation(data);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const sendVerificationCode = createAsyncThunk(
  'reservation/sendVerificationCode',
  async (
    data: {
      id: number;
      name: string;
      email: string;
    },
    { rejectWithValue },
  ): Promise<Result | any> => {
    try {
      return await reservationService.sendVerificationCode(data);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const validateVerificationCode = createAsyncThunk(
  'reservation/validateVerificationCode',
  async (
    data: { id: number; code: string },
    { rejectWithValue },
  ): Promise<Result | any> => {
    try {
      return await reservationService.validateVerificationCode(data);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export default slice.reducer;
