import styled from 'styled-components';

import _FormCheck from 'react-bootstrap/FormCheck';
import _FormControl from 'react-bootstrap/FormControl';
import _FormGroup from 'react-bootstrap/FormGroup';
import _FormLabel from 'react-bootstrap/FormLabel';
import _FormSelect from 'react-bootstrap/FormSelect';
import _Image from 'react-bootstrap/Image';

import ArrowSelectSVG from './assets/svg/arrow-select.svg';

export const Container = styled.div``;

export const Text = styled.div<{
  color?: string;
  lineHeight?: number;
  size?: number;
  weight?: number;
}>`
  font-family: Raleway;
  color: ${(props) => (props.color ? props.color : '#F3C')};
  font-size: ${(props) => (props.size ? `${props.size}px` : '14px')};
  font-weight: ${(props) => (props.weight ? `${props.weight}` : '400')};
  ${(props) => props.lineHeight && `line-height:${props.lineHeight}px;`}
`;

export const ImageHeader = styled.div<{
  src?: string;
}>`
  width: 100%;
  height: 150px;
  border-radius: 15px;
  background: url('${(props) => props.src}') no-repeat;
  background-size: cover;
  background-position: center;
`;

export const Image = styled(_Image)``;

// export const InputText = styled(_FormText)`
//   width: 100%;
//   height: 40px;
//   background: #FAFAFA;
//   border: 1px solid #CCCCCC;
//   border-radius: 6px;
//   color: #888888;
//   font-family: 'Raleway';
//   font-style: normal;
//   font-weight: 600;
//   font-size: 14px;
//   line-height: 16px;
// `;

export const Separator = styled.hr`
  border: 1px dashed #c4c4c4;
  height: 0;
  background: #ffffff;
`;

export const FormGroup = styled(_FormGroup)``;

export const FormLabel = styled(_FormLabel)`
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #505050;
`;

export const FormControl = styled(_FormControl)`
  height: 40px;
  background: #fafafa;
  border: 1px solid #cccccc;
  border-radius: 6px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #888888;
`;

export const FormSelect = styled(_FormSelect)`
  height: 40px;
  background: #fafafa;
  border: 1px solid #cccccc;
  border-radius: 6px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #888888;
  background-image: url(${ArrowSelectSVG});
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
`;

export const FormCheck = styled(_FormCheck)`
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;
