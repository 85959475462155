import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Genre } from '../interfaces/genre.interface';

import genreService from '../services/genre.service';

interface ReservationState {
  data: Genre[];
  loading: boolean;
}

const initialState: ReservationState = {
  data: [],
  loading: false,
};

export const fetchGenres = createAsyncThunk(
  'genre/fetchGenres',
  async (): Promise<Genre[]> => {
    return genreService.fetchGenres();
  },
);

const slice = createSlice({
  name: 'genre',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchGenres.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchGenres.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(fetchGenres.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default slice.reducer;
