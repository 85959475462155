import API from './api';

export const fetchGenres = async (): Promise<any[]> => {
  const res = await API.get('/genre');
  return res.data;
};

const funcs = {
  fetchGenres,
};

export default funcs;
