import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import cityReducer from './reducers/city.reducer';
import countryReducer from './reducers/country.reducer';
import genreReducer from './reducers/genre.reducer';
import nationalityReducer from './reducers/nationality.reducer';
import occupationReducer from './reducers/occupation.reducer';
import reasonReducer from './reducers/reason.reducer';
import reservationReducer from './reducers/reservation.reducer';
import stateReducer from './reducers/state.reducer';

export const store = configureStore({
  reducer: {
    reservation: reservationReducer,
    genre: genreReducer,
    nationality: nationalityReducer,
    occupation: occupationReducer,
    reason: reasonReducer,
    country: countryReducer,
    state: stateReducer,
    city: cityReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
