import { Text } from '../../styles';
import { Card, Container, LangButton, LangContainer } from './styles';

import { useTranslation } from 'react-i18next';
import { ReactComponent as FlagBRSvg } from '../../assets/svg/flagbr.svg';
import { ReactComponent as FlagESSvg } from '../../assets/svg/flages.svg';
import { ReactComponent as FlagUSSvg } from '../../assets/svg/flagus.svg';

type Props = {
  backgroundColor: string;
};

const ChangeLanguage = ({ backgroundColor }: Props) => {
  const { i18n, t } = useTranslation();

  const changeLanguage = (lang: string) => {
    localStorage.setItem('lang', lang);
    i18n.changeLanguage(lang);
  };

  return (
    <Container>
      <Card backgroundColor={backgroundColor}>
        <Text size={12} weight={40} color={'#505050'} lineHeight={14}>
          {t('common.changeLanguage')}:
        </Text>
        <LangContainer>
          <LangButton
            onClick={() => {
              changeLanguage('pt-BR');
            }}
          >
            <FlagBRSvg />
          </LangButton>
          <LangButton
            onClick={() => {
              changeLanguage('en-US');
            }}
          >
            <FlagUSSvg />
          </LangButton>
          <LangButton
            onClick={() => {
              changeLanguage('es-ES');
            }}
          >
            <FlagESSvg />
          </LangButton>
        </LangContainer>
      </Card>
    </Container>
  );
};

export default ChangeLanguage;
