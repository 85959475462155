import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Occupation } from '../interfaces/occupation.interface';

import occupationService from '../services/occupation.service';

interface ReservationState {
  data: Occupation[];
  loading: boolean;
}

const initialState: ReservationState = {
  data: [],
  loading: false,
};

export const fetchOccupations = createAsyncThunk(
  'occupation/fetchOccupations',
  async (): Promise<Occupation[]> => {
    return occupationService.fetchOccupations();
  },
);

const slice = createSlice({
  name: 'occupation',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOccupations.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchOccupations.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(fetchOccupations.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default slice.reducer;
