import { useFormik } from 'formik';
import { SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import {
  Container,
  FormCheck,
  FormControl,
  FormGroup,
  FormLabel,
  Separator,
} from '../../styles';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import Button from '../../components/button';
import Card from '../../components/card';
import ChangeLanguage from '../../components/change-language';
import ProgressBar from '../../components/progress-bar';

import { RootState } from '../../configStore';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { setLoading, setReservation } from '../../reducers/reservation.reducer';
import { fetchGenres } from '../../services/genre.service';
// import { fetchNationalities } from "../../reducers/nationality.reducer";
import moment from 'moment';
import { isEmail } from '../../utils/validate';

import InputMask from 'react-input-mask';
import Select from 'react-select';
import { Genre } from '../../interfaces/genre.interface';
import { Occupation } from '../../interfaces/occupation.interface';
import { fetchOccupations } from '../../services/occupation.service';
import { fetchReasons } from '../../services/reason.service';
import { formatterOnlyNumber } from '../../utils/formatter';
import occupationIds from '../../utils/occupationIds';

const IdentificationScreen = () => {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const dispatch = useAppDispatch();

  const reservation = useAppSelector(
    (state: RootState) => state.reservation.data,
  );

  const [genres, setGenres] = useState<any[]>([]);
  const [occupations, setOccupations] = useState<Occupation[]>([]);
  const [reasons, setReasons] = useState<any[]>([]);

  const [genreOptions, setGenreOptions] = useState<any[]>([]);
  const [occupationOptions, setOccupationOptions] = useState<any[]>([]);
  const [reasonOptions, setReasonOptions] = useState<any[]>([]);

  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [ddd, setDDD] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [gender, setGender] = useState<Genre | undefined>();
  // const [nationality, setNationality] = useState("");
  const [occupation, setOccupation] = useState<Occupation | undefined>();
  const [reasonId, setReasonId] = useState('');
  const [birthDate, setBirthDate] = useState('');

  useEffect(() => {
    const load = async () => {
      try {
        dispatch(setLoading(true));
        const _genres = await fetchGenres();
        if (_genres) {
          setGenres(_genres);
        }

        let _occupations = await fetchOccupations();
        if (_occupations) {
          _occupations = _occupations.filter(
            (c) => occupationIds.indexOf(c.id) !== -1,
          );
          setOccupations(_occupations);
          const _occupation = _occupations.find(
            (c) => `${c.id}` === `${reservation.guest.occupation}`,
          );
          setOccupation(_occupation);
        }

        const _reasons = await fetchReasons();
        if (_reasons) {
          setReasons(_reasons);
        }

        setReasonId(reservation.reasonId);

        setName(reservation.guest.name || '');
        setLastName(reservation.guest.lastname || '');

        if (reservation.guest.birthDate) {
          const _birthDate = moment(reservation.guest.birthDate).format(
            i18n.language === 'en-US' ? 'MMDDYYYY' : 'DDMMYYYY',
          );
          setBirthDate(_birthDate);
        }
        setEmail(reservation.guest.email || '');
        setDDD(reservation.guest.ddd ? reservation.guest.ddd.trim() : '');
        setPhoneNumber(
          reservation.guest.phoneNumber
            ? reservation.guest.phoneNumber.trim()
            : '',
        );
        // setGender(reservation.guest.gender || '');
        // setOccupation(reservation.guest.occupation || '');
        // setReasonId(reservation.reasonId || '');
      } catch (error) {
      } finally {
        dispatch(setLoading(false));
      }
    };
    load();
  }, [dispatch, reservation]);

  const loadGenres = () => {
    let _genres = genres.filter(
      (c) => c.code === i18n.language || c.code === '"pt-BR"',
    );
    _genres = _genres[0] ? _genres[0].values : [];
    const options = _genres.map((item: { code: any; description: any }) => ({
      value: item.code,
      label: item.description,
    }));
    setGenreOptions(options);
    const _gender = _genres.find((c) => c.code === reservation.guest.gender);
    setGender(_gender);
  };

  const loadReasons = () => {
    let _reasons = reasons.filter(
      (c) => c.code === i18n.language || c.code === '"pt-BR"',
    );
    _reasons = _reasons[0] ? _reasons[0].values : [];
    setReasonOptions(_reasons);
  };

  useEffect(() => {
    loadGenres();
  }, [genres]);

  useEffect(() => {
    loadReasons();
  }, [reasons]);

  useEffect(() => {
    const options = occupations.map((item: { id: any; description: any }) => ({
      value: item.id,
      label: item.description,
    }));
    setOccupationOptions(options);
  }, [occupations]);

  useEffect(() => {
    if (reservation.guest.birthDate) {
      const _birthDate = moment(reservation.guest.birthDate).format(
        i18n.language === 'en-US' ? 'MMDDYYYY' : 'DDMMYYYY',
      );
      setBirthDate(_birthDate);
    }
    loadGenres();
    loadReasons();
  }, [i18n.language]);

  // useEffect(() => {
  //   setReasonId(reservation.reasonId || (reason[0] ? reason[0].code : ''));
  // }, [dispatch, reason, reservation]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name,
      lastName,
      birthDate,
      email,
      ddd,
      phoneNumber,
      gender,
      occupation,
      submit: null,
    },
    validationSchema: Yup.object({
      name: Yup.string().required(
        t('personalInformation.errors.name.required'),
      ),
      lastName: Yup.string().required(
        t('personalInformation.errors.lastName.required'),
      ),
      birthDate: Yup.string()
        .required(t('personalInformation.errors.birthDate.required'))
        .test(
          'birthDate-check',
          t('personalInformation.errors.birthDate.isInvalid'),
          async (value) => {
            let hasError = false;
            const _birthDate = moment(
              value,
              i18n.language === 'en-US' ? 'MM/DD/YYYY' : 'DD/MM/YYYY',
            );
            if (!_birthDate.isValid()) {
              hasError = true;
            } else if (moment().diff(_birthDate.toDate(), 'days') < 0) {
              hasError = true;
            } else if (moment().diff(_birthDate.toDate(), 'years') > 100) {
              hasError = true;
            } else if (moment().diff(_birthDate.toDate(), 'years') <= 18) {
              hasError = true;
            }
            return !hasError;
          },
        ),
      email: Yup.string()
        .required(t('personalInformation.errors.email.required'))
        .test(
          'email-check',
          t('personalInformation.errors.email.isInvalid'),
          async (value) => isEmail(value),
        ),
      ddd: Yup.string()
        .required(t('personalInformation.errors.ddd.required'))
        .test(
          'ddd-length',
          t('personalInformation.errors.ddd.isInvalid'),
          async (value) => value.length === 2,
        ),
      phoneNumber: Yup.string()
        .required(t('personalInformation.errors.phoneNumber.required'))
        .test(
          'phoneNumber-ddd',
          t('personalInformation.errors.phoneNumber.isInvalid'),
          function () {
            const { ddd } = this.parent;
            return ddd && ddd.length === 2;
          },
        )
        .test(
          'phoneNumber-length',
          t('personalInformation.errors.phoneNumber.isInvalid'),
          async (value: string) => value.length === 9,
        ),
      gender: Yup.object().required(
        t('personalInformation.errors.gender.required'),
      ),
      occupation: Yup.object().required(
        t('personalInformation.errors.occupation.required'),
      ),
    }),
    onSubmit: async (values, helpers) => {
      try {
        const {
          name,
          lastName,
          birthDate,
          email,
          ddd,
          phoneNumber,
          gender,
          occupation,
        } = values;
        const _birthDate = birthDate
          ? moment(
              birthDate,
              i18n.language === 'en-US' ? 'MM/DD/YYYY' : 'DD/MM/YYYY',
            ).format('YYYY-MM-DD')
          : '';
        dispatch(
          setReservation({
            ...reservation,
            reasonId,
            guest: {
              ...reservation.guest,
              documentType: 'cpf',
              name,
              lastname: lastName,
              fullName: `${name} ${lastName}`,
              birthDate: _birthDate,
              ddd,
              phoneNumber,
              email,
              gender: gender ? gender.code : '',
              occupation: occupation ? `${occupation.id}` : '',
            },
          }),
        );
        navigate(`/${reservation.id}/email-confirmation`);
      } catch (error: any) {
        let message = 'Ops! Algo de errado aconteceu';
        if (
          error.response &&
          error.response.data &&
          error.response.data.message[0]
        ) {
          message = error.response.data.message.join('\r\n');
        }
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: message });
        helpers.setSubmitting(false);
      }
    },
  });

  return (
    <Container>
      <br />
      <ProgressBar
        color={reservation.hotel.color}
        title={t('personalInformation.title')}
        step={2}
      />
      <br />
      <Card
        title={t('identification.title')}
        checked={true}
        first={true}
        path={`/${reservation.id}/identification`}
      >
        <Card
          title={t('personalInformation.title')}
          caption={t('personalInformation.caption')}
        >
          <form noValidate onSubmit={formik.handleSubmit}>
            <FormGroup className="mb-3">
              <FormLabel>{t('personalInformation.name')}</FormLabel>
              <FormControl
                type="text"
                name="name"
                value={formik.values.name}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                isInvalid={!!(formik.touched.name && formik.errors.name)}
                maxLength={40}
              />
              <FormControl.Feedback type="invalid">
                {formik.touched.name && formik.errors.name}
              </FormControl.Feedback>
            </FormGroup>
            <FormGroup className="mb-3">
              <FormLabel>{t('personalInformation.lastName')}</FormLabel>
              <FormControl
                type="text"
                name="lastName"
                value={formik.values.lastName}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                isInvalid={
                  !!(formik.touched.lastName && formik.errors.lastName)
                }
                maxLength={39}
              />
              <FormControl.Feedback type="invalid">
                {formik.touched.lastName && formik.errors.lastName}
              </FormControl.Feedback>
            </FormGroup>
            <FormGroup className="mb-3">
              <FormLabel>{t('personalInformation.birthDate')}</FormLabel>
              <InputMask
                mask="99/99/9999"
                name="birthDate"
                value={formik.values.birthDate}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                className={`sc-iBkjds bKrZKT form-control${
                  !!(formik.touched.birthDate && formik.errors.birthDate)
                    ? ' is-invalid'
                    : ''
                }`}
              />
              <FormControl.Feedback type="invalid">
                {formik.touched.birthDate && formik.errors.birthDate}
              </FormControl.Feedback>
            </FormGroup>
            <FormGroup className="mb-3">
              <FormLabel>{t('personalInformation.email')}</FormLabel>
              <FormControl
                type="email"
                name="email"
                value={formik.values.email}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                isInvalid={!!(formik.touched.email && formik.errors.email)}
                maxLength={100}
              />
              <FormControl.Feedback type="invalid">
                {formik.touched.email && formik.errors.email}
              </FormControl.Feedback>
            </FormGroup>
            <Separator />
            <Row style={{}}>
              <Col
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  gap: '5%',
                }}
              >
                {/* <FormGroup
                                className="mb-3"
                                style={{
                                    width: "15%",
                                }}
                            >
                                <FormLabel>DDI</FormLabel>
                                <FormControl placeholder="" />
                            </FormGroup> */}
                <FormGroup
                  className="mb-3"
                  style={{
                    width: '20%',
                  }}
                >
                  <FormLabel>DDD</FormLabel>
                  <FormControl
                    type="tel"
                    name="ddd"
                    value={formik.values.ddd}
                    onBlur={formik.handleBlur}
                    onChange={(e: any) => {
                      formik.setFieldValue(
                        'ddd',
                        formatterOnlyNumber(e.target.value),
                      );
                    }}
                    isInvalid={!!(formik.touched.ddd && formik.errors.ddd)}
                    maxLength={2}
                  />
                </FormGroup>
                <FormGroup
                  className="mb-3"
                  style={{
                    width: '75%',
                  }}
                >
                  <FormLabel>{t('personalInformation.phoneNumber')}</FormLabel>
                  <FormControl
                    type="tel"
                    name="phoneNumber"
                    value={formik.values.phoneNumber}
                    onBlur={formik.handleBlur}
                    onChange={(e: any) => {
                      formik.setFieldValue(
                        'phoneNumber',
                        formatterOnlyNumber(e.target.value),
                      );
                    }}
                    isInvalid={
                      !!(
                        formik.touched.phoneNumber && formik.errors.phoneNumber
                      )
                    }
                    maxLength={9}
                  />
                </FormGroup>
              </Col>
            </Row>
            {!!(formik.touched.phoneNumber && formik.errors.phoneNumber) && (
              <div
                className="invalid-feedback"
                style={{
                  display: 'block',
                  marginTop: -10,
                }}
              >
                {formik.touched.phoneNumber && formik.errors.phoneNumber}
              </div>
            )}

            <Separator />
            <FormGroup className="mb-3">
              <FormLabel>{t('personalInformation.genre')}</FormLabel>
              <Select
                name="gender"
                value={
                  formik.values.gender
                    ? {
                        label: formik.values.gender.description,
                        value: formik.values.gender.code,
                      }
                    : undefined
                }
                onBlur={formik.handleBlur}
                onChange={(e: any) => {
                  let _genres = genres.filter(
                    (c) => c.code === i18n.language || c.code === '"pt-BR"',
                  );
                  _genres = _genres[0] ? _genres[0].values : [];
                  const _gender = _genres.find((c) => c.code === e.value);
                  formik.setFieldValue('gender', _gender);
                }}
                placeholder={t('common.select')}
                options={genreOptions}
                className={
                  !!(formik.touched.gender && formik.errors.gender)
                    ? 'is-invalid'
                    : undefined
                }
                styles={
                  !!(formik.touched.gender && formik.errors.gender)
                    ? {
                        control: (base) => ({
                          ...base,
                          borderColor: 'red',
                        }),
                      }
                    : undefined
                }
              />
              <FormControl.Feedback type="invalid">
                {formik.touched.gender && formik.errors.gender}
              </FormControl.Feedback>
            </FormGroup>
            <FormGroup className="mb-3">
              <FormLabel>{t('personalInformation.occupation')}</FormLabel>
              <Select
                name="occupation"
                value={
                  formik.values.occupation
                    ? {
                        label: formik.values.occupation.description,
                        value: formik.values.occupation.id,
                      }
                    : undefined
                }
                onBlur={formik.handleBlur}
                onChange={(e: any) => {
                  const _occupation = occupations.find((c) => c.id === e.value);
                  formik.setFieldValue('occupation', _occupation);
                }}
                placeholder={t('common.select')}
                options={occupationOptions}
                className={
                  !!(formik.touched.occupation && formik.errors.occupation)
                    ? 'is-invalid'
                    : undefined
                }
                styles={
                  !!(formik.touched.occupation && formik.errors.occupation)
                    ? {
                        control: (base) => ({
                          ...base,
                          borderColor: 'red',
                        }),
                      }
                    : undefined
                }
              />
              <FormControl.Feedback type="invalid">
                {formik.touched.occupation && formik.errors.occupation}
              </FormControl.Feedback>
            </FormGroup>
            <Separator />
            <FormGroup className="mb-3">
              <FormLabel>{t('personalInformation.reason')}</FormLabel>
              <br />
              {reasonOptions.map(
                (
                  item: { description: any; code: SetStateAction<string> },
                  index: any,
                ) => (
                  <FormCheck
                    key={`reason-${index}`}
                    type="radio"
                    label={item.description}
                    checked={reasonId === item.code}
                    onChange={() => setReasonId(item.code)}
                    inline
                  />
                ),
              )}
            </FormGroup>
            <br />
            <Button
              type="submit"
              backgroundColor={reservation.hotel.color}
              text={t('common.continue')}
            />
          </form>
          <Row className="d-block d-sm-none">
            <Col>
              <br />
              <ChangeLanguage backgroundColor={'#EEEEEE'} />
            </Col>
          </Row>
        </Card>
      </Card>
    </Container>
  );
};

export default IdentificationScreen;
