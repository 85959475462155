import { Reservation } from '../interfaces/reservation.interface';
import { Result } from '../interfaces/result.interface';

import API from './api';

const fetchReservation = async (data: { id: number }): Promise<Reservation> => {
  const { id } = data;
  const res = await API.get(`/reservation/${id}`);
  return res.data;
};

const postReservation = async (data: Reservation): Promise<Reservation> => {
  const { id } = data;
  const res = await API.put(`/reservation/${id}`, data);
  return res.data;
};

const sendVerificationCode = async (data: {
  id: number;
  name: string;
  email: string;
}): Promise<Result> => {
  const { id, name, email } = data;
  const res = await API.post(`/reservation/${id}/verification-code/send`, {
    name,
    email,
  });
  return res.data;
};

const validateVerificationCode = async (data: {
  id: number;
  code: string;
}): Promise<Result> => {
  const { id, code } = data;
  const res = await API.post(`/reservation/${id}/verification-code/validate`, {
    code,
  });
  return res.data;
};

const funcs = {
  fetchReservation,
  postReservation,
  sendVerificationCode,
  validateVerificationCode,
};

export default funcs;
