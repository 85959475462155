import { Occupation } from '../interfaces/occupation.interface';

import API from './api';

export const fetchOccupations = async (): Promise<Occupation[]> => {
  const res = await API.get('/occupation');
  return res.data;
};

const funcs = {
  fetchOccupations,
};

export default funcs;
