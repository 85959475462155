import { Nationality } from '../interfaces/nationality.interface';

import API from './api';

export const fetchNationalities = async (): Promise<Nationality[]> => {
  const res = await API.get('/nationality');
  return res.data;
};

const funcs = {
  fetchNationalities,
};

export default funcs;
