import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Nationality } from '../interfaces/nationality.interface';

import nationalityService from '../services/nationality.service';

interface ReservationState {
  data: Nationality[];
  loading: boolean;
}

const initialState: ReservationState = {
  data: [],
  loading: false,
};

export const fetchNationalities = createAsyncThunk(
  'nationality/fetchNationalities',
  async (): Promise<Nationality[]> => {
    return nationalityService.fetchNationalities();
  },
);

const slice = createSlice({
  name: 'nationality',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNationalities.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchNationalities.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(fetchNationalities.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default slice.reducer;
