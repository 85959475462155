import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL;

axios.interceptors.request.use(
  (config) => {
    const headers = config.headers || {};
    headers['x-lang'] = localStorage.getItem('lang') || 'pt-BR';
    config.headers = headers;

    const params = config.params || {};

    const domain = sessionStorage.getItem('domain');
    if (domain) {
      params['domain'] = domain;
    }

    const email = sessionStorage.getItem('email');
    if (email) {
      params['email'] = email;
    }

    config.params = params;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default axios;
