import API from './api';

export const fetchReasons = async (): Promise<any[]> => {
  const res = await API.get('/reason');
  return res.data;
};

const funcs = {
  fetchReasons,
};

export default funcs;
