import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 1%;
  justify-content: center;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
`;

export const StepContainer = styled.div<{
  width: string;
}>`
  width: ${(props) => props.width};
  text-align: center;
`;

export const Step = styled.div<{
  color: string;
}>`
  background-color: ${(props) => props.color};
  height: 5px;
`;
