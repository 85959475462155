import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { City } from '../interfaces/city.interface';

import addressService from '../services/address.service';

interface CityState {
  data: City[];
  loading: boolean;
}

const initialState: CityState = {
  data: [],
  loading: false,
};

export const fetchCities = createAsyncThunk(
  'cities/fetchCities',
  async (stateId: number): Promise<City[]> => {
    return addressService.fetchCities(stateId);
  },
);

const slice = createSlice({
  name: 'cities',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCities.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCities.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(fetchCities.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default slice.reducer;
